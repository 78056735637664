<template>
  <div>
    <div class="setting_content">
      <div class="setting_title" style="border-bottom: 1px solid #EFEFEF;">
        肉牛大屏设置
      </div>
      <div class="setting_content_content">
        <!-- 基本信息 -->
        <BasicInformation @transfer="getUser"></BasicInformation>
        <div class="line"></div>
        <!-- 摄像头 -->
        <CameraList :datascreenId='datascreenId'></CameraList>
        <div class="line"></div>
        <!-- 牛饲养地区 -->
        <CowIndustry :datascreenId='datascreenId'></CowIndustry>
        <div class="line"></div>
        <!-- 牛饲养品种 -->
        <VarietiesTableLsit :datascreenId='datascreenId'></VarietiesTableLsit>
        <div class="line"></div>
        <!-- 人员比例 -->
        <PersonnelTableLsit :datascreenId='datascreenId'></PersonnelTableLsit>
        <div class="line"></div>
        <!-- 销售价格 (单位：只) -->
        <SalesTableLsit :datascreenId='datascreenId'></SalesTableLsit>
        <div class="line"></div>
        <!-- 年存栏出栏量 -->
        <AmountTableLsit :datascreenId='datascreenId'></AmountTableLsit>
        <div class="line"></div>
        <!-- 月存栏量 -->
        <SuchTableLsit :datascreenId='datascreenId'></SuchTableLsit>
        <div class="line"></div>
        <!-- 月进出栏量 -->
        <NewSheepTableLsit :datascreenId='datascreenId'></NewSheepTableLsit>
      </div>
    </div>
  </div>
</template>

<script>
import BasicInformation from './components/dataScren8_cow/basicInformation.vue'
import CowIndustry from './components/dataScren8_cow/cowIndustry.vue'
import CameraList from './components/dataScren8_cow/cameraList.vue'

import VarietiesTableLsit from './components/dataScren8_cow/varietiesTableLsit.vue'
import PersonnelTableLsit from './components/dataScren8_cow/personnelTableLsit.vue'
import SalesTableLsit from './components/dataScren8_cow/salesTableLsit.vue'
import AmountTableLsit from './components/dataScren8_cow/amountTableLsit.vue'
import SuchTableLsit from './components/dataScren8_cow/suchTableLsit.vue'
import NewSheepTableLsit from './components/dataScren8_cow/newSheepTableLsit.vue'


export default {
  name: '',
  data(){
    return{
      datascreenId:''
    }
  },
  components: {
    BasicInformation,
    CowIndustry,
    CameraList,
    VarietiesTableLsit,
    PersonnelTableLsit,
    SalesTableLsit,
    AmountTableLsit,
    SuchTableLsit,
    NewSheepTableLsit

  },
  mounted(){

  },
  methods:{
    getUser(msg){
      this.datascreenId= msg
    }
  }
}
</script>

<style scoped>
.menu_title {
  width: 100%;
  height: 146px;
  border: 1px solid #0091FF;
  background: rgba(25, 145, 252, 0.1);
  padding: 12px 20px;
  display: flex;
  box-sizing: border-box;
}
.descrip {
  font-size: 14px;
  color: #1991FC;
  flex-shrink: 0;
  width: 100px;
}
.descrip_content {
  font-size: 14px;
  color: rgba(29, 30, 31, 0.6);
  line-height: 14px;
}
.descrip_content >p {
  margin-bottom: 12px;
}
.setting_content {
  /* padding: 24px; */
  background: #fff;
}
.setting_title {
  padding: 24px;
  padding-bottom: 6px;
}
.setting_content_content {
  padding: 24px;
}
.bootom {
  width: calc(100% - 254px);
  height: 44px;
  position: fixed;
  bottom: 0;
  left: 230px;
  background: #fff;
  margin-left: -24px;
  text-align: center;
  line-height: 44px;
}
.sub-title {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 20px;
}
.disease-box {
  position: relative;

  border: 1px solid #ccc;
  box-sizing: border-box;
  padding: 20px;
}
.el-icon-close {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  z-index: 50;
}
.line {
  width: 100%;
  height: 1px;
  margin: 30px 0;
  background: #ccc;
}

.avatar-uploader {
  width: 150px;
  height: 162px;
  border: 1px dashed #ccc;
  text-align: center;
}
.avatar-uploader .avatar {
  width: 150px;
  height: 162px;
}
</style>