<template>
  <div>
    <div class="sub-title">基础信息</div>
    <el-form :inline="true" label-width="213px" :model="form" class="demo-form-inline">
      <el-row>
        <el-col :span="6">
          <el-form-item label="年饲养量(只)">
            <el-input v-model="form.reserved1"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="年存栏量(只)">
            <el-input v-model="form.iotEquipmentCount"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="年出栏量(只)">
            <el-input v-model="form.reserved3"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="养殖基地名称">
            <el-input v-model="form.breedingBase"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="养殖基地名称数量（只）">
            <el-input v-model="form.breedingNum"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="青年牛-全场养殖数据(只)">
            <el-input v-model="form.basicCow"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="成母牛-全场养殖数据(只)">
            <el-input v-model="form.pregnantCow"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="犊牛-全场养殖数据(只)">
            <el-input v-model="form.breedCattle"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="育成牛-全场养殖数据(只)">
            <el-input v-model="form.newbornCalf"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="种公牛-全场养殖数据(只)">
            <el-input v-model="form.breedingOxen"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="育肥前期-全场养殖数据(只)">
            <el-input v-model="form.fatteningEarly"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="总面积—牛场基本信息">
            <el-input v-model="form.floorage"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="牛舍—牛场基本信息">
            <el-input v-model="form.floorNum"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="牛舍—物联网设备">
            <el-input v-model="form.reserved4"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="人效—人员信息">
            <el-input v-model="form.reserved2"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- <el-row>
        <el-form-item label="基地名称1">
          <el-input v-model="form.videoBOS[0].cowshed"></el-input>
        </el-form-item>
        <el-form-item label="基地实时画面1">
          <el-input style="width: 550px" v-model="form.videoBOS[0].url"></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="基地名称2">
          <el-input v-model="form.videoBOS[1].cowshed"></el-input>
        </el-form-item>
        <el-form-item label="基地实时画面2">
          <el-input style="width: 550px" v-model="form.videoBOS[1].url"></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="基地名称3">
          <el-input v-model="form.videoBOS[2].cowshed"></el-input>
        </el-form-item>
        <el-form-item label="基地实时画面3">
          <el-input style="width: 550px" v-model="form.videoBOS[2].url"></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="基地名称4">
          <el-input v-model="form.videoBOS[3].cowshed"></el-input>
        </el-form-item>
        <el-form-item label="基地实时画面4">
          <el-input style="width: 550px" v-model="form.videoBOS[3].url"></el-input>
        </el-form-item> -->
      </el-row>
    </el-form>
    <el-row class="ta_c">
      <el-button type="primary" style="min-width: 88px;" @click="onSaveInfo">保存</el-button>
    </el-row>

  </div>
</template>

<script>

export default {
  name: '',
  data() {
    return {
      form: {
        reserved1: '',
        iotEquipmentCount: '',
        breedingBase: '',
        breedingNum: '',
        basicCow: '',
        pregnantCow: '',
        breedCattle: '',
        allBreedFemaleCount: '',
        floorage: '',
        floorNum: '',
        reserved2:'',
        videoBOS: [
          {
            cowshed: '',
            url: ''
          }, {
            cowshed: '',
            url: ''
          }, {
            cowshed: '',
            url: ''
          }, {
            cowshed: '',
            url: ''
          }
        ]
      }
    }
  },

  mounted() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      this.qa.datascreen16QueryByParams({}).then(res => {
        if (res.respCode == '0000') {
          this.form = res.data;
          this.$emit('transfer',this.form.id)//触发transfer方法，this.user 为向父组件传递的数据
          if (res.data.videoBOS != null) {
            this.form.videoBOS = res.data.videoBOS;
          } else {
            this.form.videoBOS = [
              {
                cowshed: '',
                url: ''
              }, {
                cowshed: '',
                url: ''
              }, {
                cowshed: '',
                url: ''
              }, {
                cowshed: '',
                url: ''
              }
            ]
          }
        }
      })
    },
    onSaveInfo() {
      this.form.Authorization = sessionStorage.getItem('Authorization')
      this.qa.datascreen16DoInsert(this.form).then(res => {
        if (res.respCode == '0000') {
          this.$message({
            message: '保存成功',
            type: 'success'
          });
          this.getInfo()
        }
      })
    }
  }
}
</script>

<style scoped>
.sub-title {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 20px;
}

.avatar-uploader {
  width: 150px;
  height: 162px;
  border: 1px dashed #ccc;
  text-align: center;
}

.avatar-uploader .avatar {
  width: 150px;
  height: 162px;
}
</style>