import { render, staticRenderFns } from "./suchTableLsit.vue?vue&type=template&id=3e39b992&scoped=true&"
import script from "./suchTableLsit.vue?vue&type=script&lang=js&"
export * from "./suchTableLsit.vue?vue&type=script&lang=js&"
import style0 from "./suchTableLsit.vue?vue&type=style&index=0&id=3e39b992&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e39b992",
  null
  
)

export default component.exports