import { render, staticRenderFns } from "./cowIndustry.vue?vue&type=template&id=38a1bc26&scoped=true&"
import script from "./cowIndustry.vue?vue&type=script&lang=js&"
export * from "./cowIndustry.vue?vue&type=script&lang=js&"
import style0 from "./cowIndustry.vue?vue&type=style&index=0&id=38a1bc26&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38a1bc26",
  null
  
)

export default component.exports